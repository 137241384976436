import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        TikTok <span>from any country</span> to any country!
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        The course about targeting, creating and managing accounts.
        Without any bans, shadowbans and traffic limitation.
        Dozens of methods to use use with TikTok.
        Android and iOS Stitch Methods included!
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
     
    </Box>
  </>
)
